import './SubHeader.scss';

import React from 'react';

function SubHeader({title}) {
  return (
    <section className="subheader">
      <div className="subheader-wrapper">
          <h1 className="subheader-heading">{title}</h1>
      </div>
    </section>
  );
}

export default SubHeader;
