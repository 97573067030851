import './participant.scss';

import React from 'react';

import Button from '../../components/Button';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import TextField from '../../components/TextField';
import SubHeader from '../../components/SubHeader';

const ParticipantLoginPage = () => (
  <Layout>
    <SEO title="Participant Login" />
        <SubHeader title="Client Portal" />
    <PageHeader title="" message="Participant Login" shouldActAsContainer />
    <section className="login-group">
      <form className="login-form">
        <div className="form-header">
          <h2 className="form-header__title">Registered Customers</h2>
          <hr className="form-header__break" />
          <p className="form-header__copy">Det er en kendsgerning, at man bliver distra heret af læsbart indhold på en side, når man betragter dens layout.</p>
        </div>
        <TextField type="email" placeholder="Email*" required />
        <TextField type="password" placeholder="Password*" required />
        <Button appearance="primary">Login</Button>
      </form>
      <hr className="login-group__divider" />
      <form className="login-form">

        <div className="form-header">
          <h2 className="form-header__title">New Customers</h2>
          <hr className="form-header__break" />
          <p className="form-header__copy">By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials.</p>
        </div>
        <Button appearance="primary">Sign Up</Button>
      </form>
    </section>
  </Layout>
);

export default ParticipantLoginPage
