import './PageHeader.scss';

import React from 'react';

import { classNames } from '../../utils';

function PageHeader({title, message, className='', alignment='', shouldActAsContainer}){

  let classes = className;

  if(alignment) {
    classes = `${classes} page-header--${alignment}`;
  }

  if(shouldActAsContainer) {
    classes = `${classes} page-header--container`;
  }

  return (
    <h1 className={classNames('page-header', classes)}>
      {title}
      <span className="page-header__message">{message}</span>
    </h1>
  );
}

export default PageHeader;
